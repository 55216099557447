import { FormConfigImageItem } from "../../types/form";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FC } from "react";
import cx from "classnames";

interface ImageItemProps {
  item: FormConfigImageItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormImageItem: FC<ImageItemProps> = ({ item, checkFieldVisibility }) => {
  const { imageUrl, maxHeight, alignment } = item.params;

  if (!imageUrl) return null;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  const containerClassNames = cx("image-item", {
    "align-left": alignment === "left",
    "align-center": alignment === "center",
    "align-right": alignment === "right",
  });

  const imageClassNames = cx("image-item__image", {
    "align-cover": alignment === "cover",
  });

  return (
    <div data-testid="form-image-item" className={containerClassNames}>
      <img
        className={imageClassNames}
        style={{
          maxHeight: maxHeight ? `${maxHeight}px` : "auto",
        }}
        src={imageUrl}
        alt={`form-item-${item.slug}`}
      />
    </div>
  );
};
