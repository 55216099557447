import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RawIntlProvider } from "react-intl";

import { PageLayout } from "./components/PageLayout/PageLayout";
import { FormPageRoute } from "./pages/FormPage/FormPage";
import { FormNotFoundMessage } from "./components/FormNotFoundMessage/FormNotFoundMessage";
import { PageContentBox } from "./components/PageContentBox/PageContentBox";
import { intl } from "./utils/intl";
import { defaultLogoUrl } from "./helpers/defaultUrls";
import { FormDebugPage } from "./pages/FormDebugPage/FormDebugPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const App: React.FC = () => {
  return (
    <RawIntlProvider value={intl}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PageLayout>
            <Routes>
              {process.env.NODE_ENV === "development" && (
                <Route path="debug" element={<FormDebugPage />} />
              )}
              <Route path=":accountId/:hash" element={<FormPageRoute />} />
              <Route
                path="*"
                element={
                  <PageContentBox logoUrl={defaultLogoUrl} displayBranding={true}>
                    <FormNotFoundMessage />
                  </PageContentBox>
                }
              />
            </Routes>
          </PageLayout>
        </BrowserRouter>
      </QueryClientProvider>
    </RawIntlProvider>
  );
};
