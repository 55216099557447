import {
  KeyValueCustomMultipleSelect,
  KeyValueCustomSelect,
  Pill,
  Text,
} from "@smartsuite/react-ui/lib";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as yup from "yup";
import { getLinkedRecords } from "../../../../api";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormContext } from "../../../../hooks/useFormContext";
import { useFormField } from "../../../../hooks/useFormField";
import { Fields } from "../../../../types/fields";
import { LinkedRecordObject } from "../../../../types/form";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { fieldsStateText, fieldsText } from "../../fields.text";
import { LinkedRecordFormValue } from "../linked-record.config";

import "./LinkedRecordFieldControl.sass";
import { useIsPreview } from "../../../../hooks/useIsPreview";
import { Filter } from "@smartsuite/types";
import { useFormikContext } from "formik";
import { applyDynamicFilters, convertFilterValue } from "./linked-records.helpers";

export type LinkedRecordFieldControlProps = FormFieldProps;

export const LinkedRecordFieldControl: React.FunctionComponent<LinkedRecordFieldControlProps> = ({
  caption,
  field,
  formItem,
  label,
  name,
  required,
}) => {
  const { isPreview } = useIsPreview();

  const { values } = useFormikContext();
  const formContext = useFormContext();

  const requiredText = $t(validatorText.validationRequired);
  const formField = useFormField({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.object())
        .when({
          is: () => formItem.required,
          then: (lr) => lr.required(requiredText).min(1, requiredText),
        })
    ),
  });

  const { accountId, config } = useFormContext();

  const [options, setOptions] = useState<LinkedRecordFormValue[]>([]);
  const { data, isLoading } = useQuery(
    `${Fields.linkedrecordfield}_${field.slug}_${field.params?.linked_application}`,
    async () => {
      if (accountId && config.sharing_hash && field.params?.linked_application) {
        return await getLinkedRecords(
          accountId,
          config.sharing_hash,
          field.params.linked_application,
          field.slug,
          isPreview
        );
      }
    },
    {
      staleTime: Infinity,
    }
  );

  // Supports single or multi select of record titles based on the properties of the
  // linked record field from the form
  const isMultiple = field.params?.entries_allowed === "multiple";

  useEffect(() => {
    let filterData: Filter | undefined =
      formItem.params?.linked_record_conditions?.enabled &&
      formItem.params?.linked_record_conditions?.object
        ? formItem.params?.linked_record_conditions?.object
        : field.params?.filter_data;

    const selectedFormValues = values as { [key: string]: Array<{ id: string }> };

    if (filterData) {
      filterData = {
        ...filterData,
        fields: filterData.fields
          .map((filterField) => ({
            ...filterField,
            value: filterField?.is_dynamic_value
              ? convertFilterValue(filterField, selectedFormValues)
              : filterField.value,
          }))
          ?.filter((filterField) =>
            data?.find((record) => Object.keys(record).includes(filterField.field))
          )
          ?.filter((filterField) => !filterField.is_dynamic_value || filterField.value?.length > 0),
      };
    }

    setOptions(
      (filterData
        ? applyDynamicFilters(data ?? [], filterData, formField.value, isMultiple)
        : data ?? []
      )
        .sort((lr1, lr2) => lr1.title.toLowerCase().localeCompare(lr2.title.toLowerCase()))
        .map((linkedRecord) => ({
          id: linkedRecord.id as string,
          title: linkedRecord.title,
          linkedRecord: {
            id: linkedRecord.id as string,
            title: linkedRecord.title,
          },
        }))
    );
  }, [
    data,
    field.params?.filter_data,
    formContext.config.application.structure,
    formContext.config.form_state.items,
    formField.value,
    formItem.params?.linked_record_conditions?.enabled,
    formItem.params?.linked_record_conditions?.object,
    isMultiple,
    values,
  ]);

  const handleSelectChange = (changeValue: LinkedRecordObject | LinkedRecordObject[]): void => {
    if (changeValue) {
      if (Array.isArray(changeValue)) {
        formField.onChange(changeValue);
      } else {
        formField.onChange([changeValue]);
      }
    } else {
      formField.onChange([]);
    }
  };

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
    >
      {!isMultiple && (
        <KeyValueCustomSelect<"linkedRecord", "title", LinkedRecordFormValue>
          labelField="title"
          options={options}
          placeholder={
            isLoading ? $t(fieldsStateText.loading) : $t(fieldsText.singleSelectPlaceholder)
          }
          renderOption={(option) => (
            <Text
              tag="div"
              weight="medium"
              wordBreak="break-word"
              color="currentColor"
              className="linkedrecord-single-field-control-option"
            >
              {option.title}
            </Text>
          )}
          searchPlaceholder={$t(fieldsText.singleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value?.[0]}
          valueField="linkedRecord"
          valueFieldPath="id"
          virtualized={true}
          itemHeight={36}
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}

      {isMultiple && (
        <KeyValueCustomMultipleSelect<"linkedRecord", "title", LinkedRecordFormValue>
          labelField="title"
          options={options}
          placeholder={
            isLoading ? $t(fieldsStateText.loading) : $t(fieldsText.multipleSelectPlaceholder)
          }
          renderSelectedOption={(option) => <Pill text={option.title} />}
          renderOption={(option) => (
            <Text
              tag="div"
              weight="medium"
              wordBreak="break-word"
              color="currentColor"
              className="linkedrecord-multple-field-control-option"
            >
              {option.title}
            </Text>
          )}
          searchPlaceholder={$t(fieldsText.multipleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value}
          valueField="linkedRecord"
          valueFieldPath="id"
          virtualized={true}
          itemHeight={36}
          selectListItemsClass="linkedrecord-multiple-field-control-list"
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}
    </FormFieldControl>
  );
};
