import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import {
  Application,
  ApplicationRecordsServiceInterface,
  AppStructureField,
  AppStructureFieldParams,
  BaseRecord,
  FieldChoiceValue,
  FormulaApplicationSchema,
  ID,
  Member,
  Tag,
} from "@smartsuite/types";

export class MockAppRecordsService implements ApplicationRecordsServiceInterface {
  records: { [key: string]: BaseRecord } = {};
  currentMember: Member;

  constructor(linkedRecords: { [key: string]: BaseRecord }, currentMember: Member) {
    this.records = linkedRecords;
    this.currentMember = currentMember;
  }

  recordsByTitleService = {
    fetchRecordsByTitles: (): Promise<BaseRecord[]> => Promise.resolve<BaseRecord[]>([]),
  };

  getRelatedRecord(id: ID): BaseRecord | undefined {
    return undefined;
  }

  getMultipleRelatedRecords(ids?: ID[]): BaseRecord[] {
    return [];
  }

  ensureRecordsLoaded(applicationId: ID): void {}

  getApplication(slugOrId: ID): Application | null {
    return null;
  }

  reloadRecords(applicationId: ID, fields?: string[] | undefined): void {}

  getTagById(id: ID): Tag | undefined {
    return undefined;
  }

  getTagByLabel(label: string): Tag | undefined {
    return undefined;
  }

  getAppStructure(
    slugOrId: ID
  ): Array<AppStructureField<AppStructureFieldParams<unknown, FieldChoiceValue>>> | null {
    return null;
  }

  getApplicationRecords<T extends BaseRecord>(slugOrId: ID): T[] {
    return [];
  }

  getCurrentMember(): Member {
    return this.currentMember;
  }

  getMembers(): Member[] {
    // return [this.currentMember];
    return [];
  }

  checkUniqueness(
    applicationId: ID | undefined,
    fieldSlug: string,
    value: unknown,
    recordId?: ID | undefined
  ): Observable<boolean> {
    return of(true);
  }

  getFormulaApplicationSchema(applicationId: ID): FormulaApplicationSchema | null {
    return null;
  }

  getAllRecordsForApplication<T extends BaseRecord = BaseRecord>(idOrSlug: ID): T[] {
    return [];
  }
}

export const emptyMockAppRecordsService = new MockAppRecordsService({}, null as unknown as Member);
