import { AppField, Filter, FilterField, RecordPredicate, SimpleRecord } from "@smartsuite/types";
import { isNotNullOrUndefined } from "@smartsuite/fields-logic/lib/utils/type-guards";
import { hasAnyOf, hasNoneOf, isExactly } from "@smartsuite/fields-logic/lib/comparisons";
import { intersection, isArray } from "lodash-es";
import { LinkedRecordObject } from "../../../../types/form";

const mockedField: AppField = {
  slug: "",
  label: "",
  field_type: "linkedrecordfield",
  params: {},
};

export const applyDynamicFilters = (
  records: SimpleRecord[],
  filterData: Filter,
  selectedValues: LinkedRecordObject[],
  shouldKeepSelectedValues: boolean = false
): SimpleRecord[] => {
  const operatorFn = filterData.operator === "or" ? anyPredicate : allPredicate;

  const predicates = filterData.fields
    .filter((field) => field.is_dynamic_value)
    .map((condition) => getConditionPredicate(condition))
    .filter(isNotNullOrUndefined);

  return records.filter((record) => {
    if (
      shouldKeepSelectedValues &&
      selectedValues?.some((selectedValue) => selectedValue.id === record.id)
    ) {
      return true;
    }

    return operatorFn(predicates)(record);
  });
};

const getConditionPredicate = (condition: FilterField): RecordPredicate | null => {
  switch (condition.comparison) {
    case "has_any_of":
      return (record) => {
        const fieldValue = record[condition.field];
        return hasAnyOf(fieldValue)(condition.value, mockedField);
      };
    case "has_all_of":
    case "contains":
      return (record) => {
        const value = record[condition.field];
        return intersection(value, condition.value).length === condition.value.length;
      };
    case "is_exactly":
      return (record) => {
        const value = record[condition.field];
        return isExactly(value)(condition.value, mockedField);
      };
    case "has_none_of":
    case "not_contains":
      return (record) => {
        const value = record[condition.field];
        return hasNoneOf(value)(condition.value, mockedField);
      };
    default:
      return null;
  }
};

export function allPredicate<TRecord extends SimpleRecord>(
  predicates: Array<RecordPredicate<TRecord>>
): RecordPredicate<TRecord> {
  return (record: TRecord) => {
    for (const predicate of predicates) {
      if (!predicate(record)) {
        return false;
      }
    }
    return true;
  };
}

export function anyPredicate<TRecord extends SimpleRecord>(
  predicates: Array<RecordPredicate<TRecord>>
): RecordPredicate<TRecord> {
  return (record: TRecord) => {
    for (const predicate of predicates) {
      if (predicate(record)) {
        return true;
      }
    }
    return false;
  };
}

interface SelectedFormValues {
  [key: string]: Array<{ id: string }>;
}

export const convertFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  if (!filterField.value) {
    return filterField;
  }

  if (isArray(filterField.value)) {
    return convertArrayFilterValue(filterField, selectedFormValues);
  } else {
    return convertStringFilterValue(filterField, selectedFormValues);
  }
};

export const convertArrayFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField =>
  filterField?.value?.reduce((acc: string[], oldValue: string) => {
    if (selectedFormValues[oldValue]) {
      return [...acc, ...selectedFormValues[oldValue].map((v) => v.id)];
    }
    return acc;
  }, []);

export const convertStringFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  const dynamicTextFilterVarRegex = /(\[\[[a-z0-9_]+\]\])/g;

  const tokens = filterField.value.split(dynamicTextFilterVarRegex);

  return tokens.reduce((acc: string[], token: string) => {
    if (dynamicTextFilterVarRegex.test(token)) {
      const value = token.slice(2, -2);
      const selectedValueId = selectedFormValues[value]?.map((v) => v.id);
      if (selectedValueId) {
        return [...acc, ...selectedValueId];
      }
    } else {
      if (token) {
        return [...acc, token];
      }
    }

    return acc;
  }, []);
};
