import axios from "axios";
import { SharedFile, SharedFileInput } from "./types/file";
import { BaseRecord, FormConfig, Member } from "./types/form";
import { SimpleRecord } from "@smartsuite/types";

function baseUrl(): string {
  return process.env.REACT_APP_SMARTSUITE_API_URL ?? "";
}

export async function fetchFormConfig(
  accountId: string,
  hash: string,
  isPreview: boolean = false
): Promise<FormConfig> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function saveFormRecord(
  accountId: string,
  hash: string,
  record: BaseRecord,
  isPreview: boolean = false
): Promise<void> {
  return await axios
    .post(`${baseUrl()}/api/v1/forms/${hash}/create_record/`, record, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function createSharedFiles(
  accountId: string,
  hash: string,
  files: SharedFileInput[],
  isPreview: boolean = false
): Promise<SharedFile[]> {
  return await axios
    .post(`${baseUrl()}/api/v1/forms/${hash}/create_shared_files/`, files, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function getLinkedRecords(
  accountId: string,
  hash: string,
  appId: string,
  fieldSlug: string,
  isPreview: boolean = false
): Promise<SimpleRecord[]> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}/linked_records/?app=${appId}&slug=${fieldSlug}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data.map((record: SimpleRecord) => ({ ...record, id: record._id })));
}

export async function getAssignedToMembers(
  accountId: string,
  hash: string,
  fieldSlug: string,
  isPreview: boolean = false
): Promise<Member[]> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}/assigned_to_values/?slug=${fieldSlug}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}
